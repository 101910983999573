<template>
    <v-container fluid>
        <v-row>
            <v-col
                cols="12"
                md="8"
            >
                <v-card
                    tile
                    elevation="2"
                >
                    <v-card-title class="py-1">
                        {{ $t('orders') }}
                    </v-card-title>

                    <v-data-table
                        :headers="headers"
                        :items="customerOrdersList"
                        :items-per-page="10"
                        class="elevation-2"
                        :sort-by="'orderDate'"
                        sort-desc
                        :footer-props="{
                            itemsPerPageOptions: [10,20,50, -1],
                            'items-per-page-text': $t('entranceList-nrOfItems')
                        }"
                    >
                        <template v-slot:item.utcDateCreated="{ item }">
                            {{ item.utcDateCreated | luxon({ input: "formatutc", output: "formatlocal" }) }}
                        </template>
                        <template v-slot:item.totalPriceWithoutVatInMinorCurrency="{ item }" class="text-right" style="align-self: right;">
                            <div class="text-right">
                                {{ globalConvertMinorCurrencyToLocal(item.totalPriceWithoutVatInMinorCurrency) }}
                            </div>
                        </template>
                        <template v-slot:item.totalPriceIncludingVatInMinorCurrency="{ item }" class="text-right">
                            <div class="text-right">
                                {{ globalConvertMinorCurrencyToLocal(item.totalPriceIncludingVatInMinorCurrency) }}
                            </div>
                        </template>
                        <template v-slot:item.id="{ item }">
                            <v-btn
                                text
                                @click="getOrderPdf(item.id)"
                            >
                                <v-icon left>
                                    mdi-file-pdf
                                </v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data() {
        return {
            customerOrdersList: [],
            headers: [
                { text: this.$t('order-orderDate'), value: 'utcDateCreated' },
                {text: this.$t('attentiontype-0'), value: 'registrationNumber'},
                { text: this.$t('totalExc'), value: 'totalPriceWithoutVatInMinorCurrency', align: 'right'},
                { text: this.$t('totalInc'), value: 'totalPriceIncludingVatInMinorCurrency', align: 'right'},
                { text: this.$t('order-invoice'), value: 'id' }
            ]
        }
    },
    computed: {        
        ...mapGetters({
            getEmailConfirmed: 'getEmailConfirmed',
            getSelectedCvrNumber: 'getSelectedCvrNumber',
            getSelectedPNumber: 'getSelectedPNumber'
        })
    },
    created() {
        this.FetchCustomerOrders({pNumber: this.getSelectedPNumber})
            .then(result =>{
                this.customerOrdersList = result.orders
            })
    },
   
    methods: {
        getOrderPdf(orderId) {
            this.FetchOrderPdf({pNumber: this.getSelectedPNumber, orderId: orderId})
        },
        ...mapActions({
            FetchCustomerOrders: 'FetchCustomerOrders',
            FetchOrderPdf: 'FetchOrderPdf'
        })      
    },
}
</script>

<style scoped>
.headerClass {
    text-align: right;
}
</style>