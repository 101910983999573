import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"8"}},[_c(VCard,{attrs:{"tile":"","elevation":"2"}},[_c(VCardTitle,{staticClass:"py-1"},[_vm._v(" "+_vm._s(_vm.$t('orders'))+" ")]),_c(VDataTable,{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.customerOrdersList,"items-per-page":10,"sort-by":'orderDate',"sort-desc":"","footer-props":{
                        itemsPerPageOptions: [10,20,50, -1],
                        'items-per-page-text': _vm.$t('entranceList-nrOfItems')
                    }},scopedSlots:_vm._u([{key:"item.utcDateCreated",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("luxon")(item.utcDateCreated,{ input: "formatutc", output: "formatlocal" }))+" ")]}},{key:"item.totalPriceWithoutVatInMinorCurrency",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.globalConvertMinorCurrencyToLocal(item.totalPriceWithoutVatInMinorCurrency))+" ")])]}},{key:"item.totalPriceIncludingVatInMinorCurrency",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.globalConvertMinorCurrencyToLocal(item.totalPriceIncludingVatInMinorCurrency))+" ")])]}},{key:"item.id",fn:function(ref){
                    var item = ref.item;
return [_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.getOrderPdf(item.id)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-file-pdf ")])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }